<template>
  <form v-if="!preventBind" @submit.prevent="save" class="w-m-content financeiro-window" :class="{loading: loading}">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Categoria de {{label}}</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size2 fin-form">
      <e-row mr>
        <e-col>
          <erp-s-field label="Descrição" required>
            <erp-input autofocus v-model="model.descricao" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Aparecer dentro de" icon-help="Indica a hierarquia da categoria">
            <categoria-hirerarquia-select :tipo="tipoInt" v-model="model.parent" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field label="Associar com DRE" icon-help="Indica a relação da categoria financeira com o Demonstrativo de Resultado do Exercício (DRE), relatório que exibe lucro ou prejuízo.">
            <d-r-e-select :tipo="tipoInt" v-model="model.dre" />
          </erp-s-field>
        </e-col>
        <e-col>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="submit" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px"/>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newCategoria as newEndpoint, updateCategoria as update, findCategoria as find} from "@/domain/financeiro/services/categoria"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import mockCategoria from "@/domain/financeiro/helpers/mockCategoria"
import CategoriaHirerarquiaSelect from "@/components/financeiro/components/input/CategoriaHirerarquiaSelect";
import DRESelect from "@/components/financeiro/components/input/DRESelect"

export default {
  name: 'CategoriaWindowModern',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {money: VMoney},
  components: {
    DRESelect,
    CategoriaHirerarquiaSelect,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
  },
  props: ['id', 'tipo', 'options', 'router', 'parent'],
  data() {
    let mock = JSON.parse(JSON.stringify(mockCategoria))
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      status: '',
      preventBind: false,
      model: mock
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      if (this.parent) {
        console.log('OPA', this.parent)
        this.model.parent = {
          ...this.parent,
          label: this.parent.descricao,
          value: this.parent.id
        }
      }
    }
  },
  computed: {
    tipoInt () {
      return this.tipo === 1 || this.tipo === '1' || this.tipo === 'receita' ? 1 : 2
    },
    label () {
      return this.tipoInt === 1 ? 'Receita' : 'Despesa'
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.model.id = response.data.id
            }
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))

      if (!data.tipo) {
        data.tipo = this.tipoInt
      }

      const relacoes = ['parent', 'dre']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      return data
    },
    prepareToEdit(data) {
      if (data.parent) {
        data.parent = {
          ...data.parent,
          label: data.parent.descricao,
          value: data.parent.id,
        }
      }
      if (data.dre) {
        data.dre = {
          ...data.dre,
          label: data.dre.descricao,
          value: data.dre.id,
        }
      }
      return data
    },
    save() {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newEndpoint
      if (this.model.id) {
        method = update
      }
      method(data)
          .then(response => {
            this.loading = false
            console.log(response)
            this.$uloc.window.emit(this.$root.wid, 'created', response.data)
            // this.$uloc.window.close(this.$root.wid)
            if (this.id || this.model.id) {
              this.dg()
            } else {
              this.$uloc.notify({
                color: 'black',
                message: `Categoria com ID #"${response.data.id}" criada.`,
                position: 'bottom-left',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
              this.$uloc.window.close(this.$root.wid)
            }
            this.model.id = response.data.id
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
      this.$uloc.window.emit(this.$root.wid, 'updated', this.lista)
      this.success = true
    }
  }
}
</script>
