<template>
  <list-select ref="select" v-on:input="(v) => $emit('input', v)" :search-data="search"
               v-model="model" placeholder="Não mostrar no DRE Gerencial"/>
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import {listDRE} from "@/domain/financeiro/services/categoria"

export default {
  name: "DRESelect",
  props: {
    value: {
      required: true
    },
    tipo: {
      required: false
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  watch: {
    value(v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    search(terms) {
      console.log(terms)
      return new Promise((resolve, reject) => {
        listDRE()
            .then(response => {
              console.log(response.data)
              if (!response.data) return []
              let list = this.tipo ? response.data.filter(c => Number(c.tipo) === Number(this.tipo)) : response.data
              list = list.map(dre => {
                return {
                  ...dre,
                  label: dre.descricao,
                  value: dre.id
                }
              })
              list.unshift({
                label: 'Não mostrar no DRE Gerencial',
                value: 2,
                disabled: true,
                selectable: true
              })
              resolve(list)
            })
            .catch(error => {
              this.alertApiError(error)
              reject(error)
            })
        /*setTimeout(() => {
          let list = UFs.map((obj) => {
            return {
              ...obj,
              label: obj.sigla,
              value: obj.sigla
            }
          }).sort((a, b) => {
            if (a.nome > b.nome) {
              return 1;
            }
            if (a.nome < b.nome) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
          resolve(list)
        })*/
      })
    }
  }
}
</script>
